const date = new Date();
const year = date.getFullYear();

// @ts-ignore
const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);
export const yearList = [];
for (let i = year; i <= (year + 30); i++) {
    yearList.push({label: i.toString().substr(-2), value: i.toString().substr(-2)});
}

export const monthList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const jpYearFormat = {
    yearListStart: 2000,
    expiryListEnd: 3,
    twoEraYear: 2019
}
