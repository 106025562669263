import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { customerPageIndex, newCarCustomerPageIndex, usedCarCustomerPageIndex } from '@shared/constants/customer.constants';
import { regex } from '@shared/constants/regex.constants';
import { GlobalValidator } from '@shared/validations/validators';
import { eFlag } from '@shared/constants/dealer.constants';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  readonly DELIMITER = '-';
  constructor(private router: Router) { }

  detectCardType(cardNumber) {
    const types = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interpayment: /^(636)\d+$/,
      unionpay: /^(62|88)\d+$/,
      VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
      Mastercard: /^[2|5][0-9]{15}$/,
      AMEX: /^3[47][0-9]{13}$/,
      DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}$/
    };

    for (const key in types) {
      if (types[key].test(cardNumber)) {
        return key;
      }
    }
  }

  checkifCustomerPageAccessibile(stepcount) {
    if (stepcount) {
      const elementCheck = customerPageIndex.find(element => {
        return element.stepCount === stepcount;
      });
      if (elementCheck) {
        this.router.navigate([elementCheck.url]);
      }
      else {
        console.log('Element not found');
      }
    } else {
      this.router.navigate(['/customer']);
    }
  }

  checkifNewCarCustomerPageAccessibile(stepcount) {
    if (stepcount) {
      const elementCheck = newCarCustomerPageIndex.find(element => {
        return element.stepCount === stepcount;
      });
      if (elementCheck) {
        this.router.navigate([elementCheck.url]);
      }
      else {
        console.log('Element not found');
      }
    } else {
      this.router.navigate(['/customer/new-car']);
    }
  }

  checkifUsedCarCustomerPageAccessibile(stepcount, source = 'home') {
    if (source) {
      usedCarCustomerPageIndex[source].find(element => {
        if (element.stepCount == (stepcount + 1)) {
          if (this.router.url.indexOf(element.url) === -1) {
            this.router.navigate([element.url]);
          }
        }
      });
    }
  }

  getUrlParameter(url, name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  format(date: NgbDateStruct, format: any): string {
    let result: string = null;
    let dayCheck: any = (date && format === 'day');
    if (dayCheck) {
      result = (date.day < 10 ? `0${date.day}` : date.day) +
        this.DELIMITER +
        (date.month < 10 ? `0${date.month}` : date.month) +
        this.DELIMITER +
        (date.year < 10 ? `0${date.year}` : date.year);
    } else if (date && format === 'year') {
      result = this.yearFormat(date);
    }
    return result;
  }

  checkNumberValidity(event, field, form, flag, subForm = null) {
    let value;
    let formField;
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
    if (subForm === null) {
      form.get(field).setValue(event.target.value);
      value = form.get(field).value;
      formField = form.get(field)
    } else {
      form.get(`${subForm}.${field}`).setValue(event.target.value);
      value = form.get(`${subForm}.${field}`).value
      formField = form.get(`${subForm}.${field}`)
    }
    if (form && !value) {
      /* */
    } else if (form && value) {
      let mobileCheck = this.mobileValidation(event, field, form, flag);
      this.mobileValueCheck(mobileCheck);
      if (flag === 'zip') {
        let zipCheck = event.target.value && !regex.JP_ZIP_REGEX.test(event.target.value);
        if (zipCheck) {
          formField.setErrors({ invalid_zip: true });
          return;
        }
      }
      if (flag === 'card') {
        if (!GlobalValidator.validateCard(event.target.value)) {
          formField.setErrors({ invalid_card: true });
          return;
        }
      }
      formField.setErrors(null);
    }
  }

  formatPhoneNumber = (str, format10 = '3,3,4', format11 = '3,4,4') => {
    try {
      let match;
      const cleaned = ('' + str).replace(/\D/g, '');
      if (str && str.length == 10) {
        const formatDigits = format10.split(',');
        const regex = `^(\\d{${formatDigits[0]}})(\\d{${formatDigits[1]}})(\\d{${formatDigits[2]}})$`;
        const re = new RegExp(regex);
        match = cleaned.match(re);
      } else if (str && str.length == 11) {
        const formatDigits = format11.split(',');
        const regex = `^(\\d{${formatDigits[0]}})(\\d{${formatDigits[1]}})(\\d{${formatDigits[2]}})$`;
        const re = new RegExp(regex);
        match = cleaned.match(re);
      }
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
      }
      return cleaned;
    } catch (e) {
      console.log(e);
    }
  }

  checkNumber_userTypes_Validity(event, field, form, flag) {
    if (event.inputType == 'insertFromPaste') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      form.get(field).setValue(event.target.value);
    }
    if (form && !form.get(field).value) {
    } else if (form && form.get(field).value) {
      let mobileCheck = this.mobileValidation(event, field, form, flag);
      if (mobileCheck) {
        return;
      }
      if (flag === 'zip') {
        if (event.target.value && !regex.JP_ZIP_REGEX.test(event.target.value)) {
          form.get(field).setErrors({ invalid_zip: true });
          return;
        }
      }
      if (flag === 'card') {
        if (!GlobalValidator.validateCard(event.target.value)) {
          form.get(field).setErrors({ invalid_card: true });
          return;
        }
      }
      form.get(field).setErrors(null);
    }
  }
  /**
 * @method trimStartSpace
 * @description Function to trim space in starting
 */
  trimStartSpace(event, field: string, form: any, subForm = null) {
    event.target.value = event.target.value.trimStart();
    if (subForm !== '' && subForm !== null) {
      form.get(`${subForm}.${field}`).setValue(event.target.value);
    } else {
      form.get(`${field}`).setValue(event.target.value);
    }
  }

  /**
* @method trimStartSpace
* @description Function to trim space in last
*/
  trimLastSpace(event, field: string, form: any, subForm = null) {
    event.target.value = event.target.value.trim();
    if (subForm !== '' && subForm !== null) {
      form.get(`${subForm}.${field}`).setValue(event.target.value);
    } else {
      form.get(`${field}`).setValue(event.target.value);
    }
  }

  mobileValidation(event, field, form, flag) {
    if (flag === 'mobile') {
      if (event.target.value && !regex.JP_MOBILE_REGEX.test(event.target.value)) {
        form.get(field).setErrors({ invalid_mobile: true });
        return true;
      }
    }
  }

  yearFormat(date: NgbDateStruct) {
    let result = (date.year < 10 ? `0${date.year}` : date.year) +
      this.DELIMITER +
      (date.month < 10 ? `0${date.month}` : date.month) +
      this.DELIMITER +
      (date.day < 10 ? `0${date.day}` : date.day);
    return result;
  }
  mobileValueCheck(mobileCheck) {
    if (mobileCheck) {
      return;
    }
  }

  redirectToRenewSubscription(renewSubscription) {
    if (renewSubscription.key) {
      let redirectUrl='/dealer/demo-car/register-order-number/list?key=' + renewSubscription.key + '&expiry=' +renewSubscription.expiry;
      this.router.navigateByUrl(redirectUrl);
    }
 }

  getPageIndex(totalRenewalCount, defaultPageSize, currentPageIndex) {
    let offset = Math.ceil(totalRenewalCount / defaultPageSize);
    let currentIndex = currentPageIndex + 1;
    if (offset > currentIndex) {
      currentPageIndex = currentIndex;
      return currentPageIndex;
    }
  }

  getOldMonths(date, months) {
    date.setMonth(date.getMonth()-months);
    return date;
  }

  setCurrentDateStr() {
    let currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2,'0');
    const day = String(currentDate.getDate()).padStart(2,'0');
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }
  
  checkEqualArrays(array1, array2) {
    return Array.isArray(array1) && Array.isArray(array2) && array1.length === array2.length && array1.every((val, index) => val === array2[index]);
  }

  getChangedValuesIndex(array1, array2) {
    let index = [];
    array1.some((x,y) => {
      if(x !== array2[y]) {
        index.push(y);
      }
    })
    return index;
  }

  getFailedPaymentDtls(paymentStatus,response?) {
    let failedPaymentDetails = {
      enableSubmit : true,
      paymentUnSupported:false,
      paymentFailureMsg: ''
      };
    if(paymentStatus === 'PAYMENT_UNSUPPORTED') {
      failedPaymentDetails.paymentUnSupported = true;
    } else {
      failedPaymentDetails.paymentUnSupported = false;
      failedPaymentDetails.paymentFailureMsg = response.message;
    }
    return failedPaymentDetails;
  }

  
  /*
 *tokenFromUrlParams()
 *checking URL has encode token flag to decode token
 */
  tokenFromUrlParams(urlParam: string) {
    const eFlagValue = this.getUrlParameter(window.location.href, eFlag.key);
    if (eFlagValue == 'true') {
      const currengtUrl = window.location.href;
      const urlParamsArray = currengtUrl.split('&');
      const encodedToken = urlParamsArray[1];
      const token = atob(encodedToken);
      return token;
    }
    return this.getUrlParameter(window.location.href, urlParam);
  }
  
  formatMonthInexpiryDate(expiryDate) {
    const year= decodeURIComponent(expiryDate).split('/')[0];
    let month = decodeURIComponent(expiryDate).split('/')[1];
    month = month.toString().padStart(2,'0');
    return  year + '/' + month;
  }

  /**
 * @method getDateFromDateString
 * @description Function to create the new date obj given the date input in YYYY/MM string Format
 */
  getDateFromDateString(dateStr) {
    const parts = dateStr.split('/');
    const year = parseInt(parts[0],10);
    const month = parseInt(parts[1],10)- 1;
    return new Date(year,month);
  }

  checkNfsCardError(err) {
    let isNfsCardError:boolean = false;
    if(err.error && err.error.status == 5001) {
      isNfsCardError = true;
    }
    return isNfsCardError;
  }
}
